<template>
    <div v-if="dataSource && dataSourceType === 'ProtocolDocument'"
        :key="document.id"
        class="p-relative"
    >
        <Toolbar 
            :is-list="false"
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <DocumentPanel :document-info="dataSource.Data.Object" />
        
        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab 
                v-for="item in pages"
                :key="document.id + item.FormId"
                @click="onTabClicked(item.FormId)"
            >
                <template v-if="item.Count < 0">
                    {{ item.Name }}
                </template> 
                <template v-else>
                    {{ item.Name }}
                    <span class="count" id="attachCounter">{{item.Count}}</span>
                </template>   
            </v-tab>

        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item 
                v-for="item in pages"
                :key="document.id + item.FormId"
            >
                <template v-if="item.FormId == document.id">
                    <v-form class="wrapperForm" ref="form" lazy-validation>
                        <v-row dense>

                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <!--Блок Сведения о регистрации-->
                                <v-card v-if="isRegistered" flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Сведения_о_регистрации")}}</div>
                                        
                                        <!--Поле Номер регистрации, дата-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Номер_регистрации,_дата")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{ concatedRegistrationData  }}</div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Регистратор-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Регистратор")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    <v-workplace-chip :id="document.Card.RegistratorWorkplaceId" :name="document.Card.RegistratorName" />
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                                
                                <!--Блок Контроль-->
                                <v-card flat v-if="document.is_new_record">
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Контроль")}}</div>

                                        <!--Тип контроля-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="3">
                                                <label class="f-label">{{$t("Тип_контроля")}}</label>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="9">
                                                <v-select
                                                    v-model="controlType"
                                                    :items="controlTypes"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredNotZeroRule"
                                                    append-icon="fas fa-chevron-down"
                                                >
                                                </v-select>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters v-if="controlType == 101">
                                            <v-col cols="12" sm="12" md="3">
                                                <label class="f-label">{{$t("Тип_периода")}}</label>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="9">
                                                <v-select
                                                    v-model="periodControlType"
                                                    :items="periodControlTypes"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                    @change="calcControlDate"
                                                >
                                                </v-select>
                                            </v-col>

                                        </v-row>

                                        <v-row no-gutters v-if="periodControlType == 1 && controlType == 101">
                                            <v-col cols="12" sm="12" md="3">
                                                
                                            </v-col>
                                            <v-col cols="12" sm="12" md="9">
                                                <div class="more-inputs-in-row">
                                                    <span>{{ $t("Каждые") }}</span>
                                                    <v-text-field
                                                        v-model="everydayDelta"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        type="number"
                                                        :rules="everydayPeriodRule"
                                                        class="cust-inputnumber"
                                                        min="1"
                                                        max="365"
                                                        @input="calcControlDate"
                                                        @change="calcControlDate"
                                                    ></v-text-field>

                                                    <span>{{ $t("дня/дней,_начиная_с") }}</span>

                                                    <v-menu 
                                                        v-model="everydayDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                :value="everydayDate | formattedDate"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                hide-details
                                                                required
                                                                outlined
                                                                dense
                                                                class="datepick-input"
                                                                :rules="requiredRule"
                                                            >
                                                            </v-text-field>
                                                        </template>

                                                        <v-date-picker
                                                            v-model="everydayDate"
                                                            color="teal"
                                                            :first-day-of-week="1"
                                                            @change="calcControlDate"
                                                            :min="todayDate"
                                                            :allowed-dates="allowedDates"
                                                            @input="everydayDateMenu = false"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                    
                                                </div>
                                            </v-col>
                                        </v-row> 

                                        <v-row no-gutters v-if="periodControlType == 2 && controlType == 101">
                                            <v-col cols="12" sm="12" md="3">
                                                
                                            </v-col>
                                            <v-col cols="12" sm="12" md="9">
                                                <div class="more-inputs-in-row">
                                                    <span>{{ $t("Каждый") }}</span> 
                                                    <v-select
                                                        v-model="workWeekDay"
                                                        :items="workWeekDays"
                                                        :item-text="item => $refLocale(item, $i18n.locale)"
                                                        item-value="id"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        append-icon="fas fa-chevron-down"
                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                        @change="calcControlDate"
                                                    >
                                                    </v-select>
                                                </div>
                                            </v-col>
                                        </v-row> 

                                        <v-row no-gutters v-if="periodControlType == 3 && controlType == 101">
                                            <v-col cols="12" sm="12" md="3">
                                                
                                            </v-col>
                                            <v-col cols="12" sm="12" md="9">
                                                <div class="more-inputs-in-row">
                                                    <span>{{ $t("Каждые") }}</span>
                                                    <v-text-field
                                                        v-model="monthlyDelta"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        type="number"
                                                        :rules="monthlyPeriodRule"
                                                        class="cust-inputnumber"
                                                        min="1"
                                                        max="12"
                                                        @input="calcControlDate"
                                                        @change="calcControlDate"
                                                    ></v-text-field>

                                                    <span>{{ $t("месяца/-ев,_начиная_с") }}</span>

                                                    <v-menu 
                                                        v-model="monthlyDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                :value="monthlyDate | formattedDate"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                hide-details
                                                                required
                                                                outlined
                                                                dense
                                                                class="datepick-input"
                                                                :rules="requiredRule"
                                                            >
                                                            </v-text-field>
                                                        </template>

                                                        <v-date-picker
                                                            v-model="monthlyDate"
                                                            color="teal"
                                                            :first-day-of-week="1"
                                                            @change="calcControlDate"
                                                            :min="todayDate"
                                                            :allowed-dates="allowedDates"
                                                            @input="monthlyDateMenu = false"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                    
                                                </div>
                                            </v-col>
                                        </v-row> 

                                        <v-row no-gutters v-if="periodControlType == 4 && controlType == 101">
                                            <v-col cols="12" sm="12" md="3">
                                                
                                            </v-col>
                                            <v-col cols="12" sm="12" md="9">
                                                <div class="more-inputs-in-row">
                                                    <span>{{ $t("Каждый") }}</span>
                                                    <v-text-field
                                                        v-model="kvartalDelta"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        type="number"
                                                        :rules="kvartalPeriodRule"
                                                        class="cust-inputnumber"
                                                        min="1"
                                                        max="60"
                                                        @input="calcControlDate"
                                                        @change="calcControlDate"
                                                    ></v-text-field>

                                                    <span>{{ $t("день,_нового_отчетного_периода") }}</span>
                                                </div>
                                            </v-col>
                                        </v-row> 

                                        <v-row no-gutters v-if="periodControlType == 5 && controlType == 101">
                                            <v-col cols="12" sm="12" md="3">
                                                
                                            </v-col>
                                            <v-col cols="12" sm="12" md="9">
                                                <div class="more-inputs-in-row">
                                                    <span>{{ $t("Раз_в_год,_начиная_с") }}</span>
                                                    
                                                    <v-menu 
                                                        v-model="anuallyDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                :value="anuallyDate | formattedDate"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                hide-details
                                                                required
                                                                outlined
                                                                dense
                                                                class="datepick-input"
                                                                :rules="requiredRule"
                                                            >
                                                            </v-text-field>
                                                        </template>

                                                        <v-date-picker
                                                            v-model="anuallyDate"
                                                            color="teal"
                                                            :first-day-of-week="1"
                                                            @change="calcControlDate"
                                                            :min="todayDate"
                                                            @input="anuallyDateMenu = false"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                    
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters v-if="periodControlType == 6 && controlType == 101">
                                            <v-col cols="12" sm="12" md="3">
                                                
                                            </v-col>
                                            <v-col cols="12" sm="12" md="9">
                                                <div class="more-inputs-in-row">
                                                    <span>{{ $t("Начиная_с") }}</span>
                                                    
                                                    <v-menu 
                                                        v-model="randomDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                :value="randomDate | formattedDate"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                hide-details
                                                                required
                                                                outlined
                                                                dense
                                                                class="datepick-input"
                                                                :rules="requiredRule"
                                                            >
                                                            </v-text-field>
                                                        </template>

                                                        <v-date-picker
                                                            v-model="randomDate"
                                                            color="teal"
                                                            :first-day-of-week="1"
                                                            @change="calcControlDate"
                                                            :min="todayDate"
                                                            @input="randomDateMenu = false"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                    
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters v-if="[1, 3, 4, 5, 6].includes(periodControlType) && controlType == 101">
                                            <v-col cols="12" sm="12" md="3">
                                                <label class="f-label">{{$t("Учитывать_выходные_дни")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="9">
                                                <v-switch
                                                    v-model="isConsiderWeekends"
                                                    inset
                                                    hide-details
                                                    class="cust-switch"
                                                />
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="3">
                                                <label class="f-label">{{$t("Контрольный_срок")}}</label>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="9">
                                                <v-menu 
                                                    v-model="controlDateMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290"
                                                    :disabled="controlType == 101"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            :value="controlDate | formattedDate"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            outlined
                                                            dense
                                                            class="datepick-input"
                                                            required
                                                            :rules="controlType != 101 ? requiredRule : []"
                                                            hide-details
                                                        >
                                                        </v-text-field>
                                                    </template>

                                                    <v-date-picker
                                                        v-model="controlDate"
                                                        @input="controlDateMenu = false"
                                                        color="teal"
                                                        :min="minDate"
                                                        :max="maxData"
                                                        :first-day-of-week="1"
                                                    >
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>

                                        <v-row no-gutters v-if="controlType == 101">
                                            <v-col cols="12" sm="12" md="3">
                                                <label class="f-label">{{$t("Окончание_периода")}}</label>    
                                            </v-col>
                                            <v-col cols="12" sm="12" md="9">
                                                <v-menu 
                                                    v-model="endDateMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            :value="periodEndDate | formattedDate"
                                                            prepend-icon="mdi-calendar"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on" 
                                                            hide-details
                                                            required
                                                            outlined
                                                            dense
                                                            class="datepick-input"
                                                            :rules="requiredRule"
                                                        >
                                                        </v-text-field>
                                                    </template>

                                                    <v-date-picker
                                                        v-model="periodEndDate"
                                                        color="teal"
                                                        :first-day-of-week="1"
                                                        :min="controlDate ? controlDate : todayDate"
                                                        @input="endDateMenu = false"
                                                    >
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>

                                <!--Блок Реквизиты документа-->
                                <v-card flat>
                                    <v-card-text>

                                        <div class="form-box-title">{{$t("Реквизиты_документа")}}</div>

                                        <!--Поле Номер пункта-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Номер_пункта")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.SectionNumber ? document.Card.SectionNumber : $t("Не_указано") }}
                                                </div>
                                                <v-text-field
                                                    v-else
                                                    :value="document.Card.SectionNumber"
                                                    @input="update({ property: 'Data.Object.Document.Card.SectionNumber', value: $event })"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    maxlength = 10
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <!--Поле "Версии" пункта-->
                                        <v-row no-gutters v-if="!isViewMode">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Новый_тип")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <v-switch
                                                    v-model="typeIsIAS"
                                                    inset
                                                    hide-details
                                                    class="cust-switch"
                                                />
                                            </v-col>
                                        </v-row>

                                        <!--поле Вид документа-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Тип_документа") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{document.Card.DocumentType ? document.Card.DocumentType.Value : $t("не_указано")}}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--поле Тип протокола-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Тип_протокола") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.ProtocolType ? document.Card.ProtocolType.Value : $t("не_указано") }}
                                                </div>
                                                <v-select
                                                    v-else
                                                    :value="document.Card.ProtocolType"
                                                    @input="update({ property: 'Data.Object.Document.Card.ProtocolType', value: $event})"
                                                    :items="protocolTypeItems"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    outlined
                                                    dense
                                                    required
                                                    :rules="requiredRule"
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-text-field 
                                                                    :placeholder="$t('Поиск')"
                                                                    class="mb-2 autocomplete-search"
                                                                    hide-details
                                                                    dense
                                                                    clearable
                                                                    autofocus
                                                                    v-model="protocolTypeSearch"
                                                                >
                                                                </v-text-field>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>

                                        <!--поле Характер вопроса-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Характер_вопроса")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ document.Card.QuestCharacter ? document.Card.QuestCharacter.Value : $t("не_указано") }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--поле Язык документа-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Язык_документа")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">

                                                <div class="onlyReadData">
                                                    {{document.Card.Languages ? documentLanguages.map((x) => x.Value).join(', ') : $t("Не_указано")}}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--поле Тип носителя-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Тип_носителя")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">

                                                <div class="onlyReadData">
                                                    {{document.Card.CarrierType ? document.Card.CarrierType.Value : $t("Не_указано")}}
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                            </v-col>
                            
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <template v-if="!document.is_new_record && isRegistered">
                                    <!--ToDo Панелька статуса документа-->
                                    <DocumentStatusCard 
                                        :controlInfo="controlInfo"
                                    />
                                </template>

                                <!--Секция Корреспондент-->
                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Корреспондент")}}</div>

                                        <!--поле Отправитель-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Отправитель")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="icon-click-row-group icrg-align-center">                                                    
                                                    <div class="onlyReadData">
                                                        <v-enterprise-chip v-if="document.Card.Correspondent.Name" :name="document.Card.Correspondent.Name" />
                                                        <div class="chipAreaNoData" v-if="!document.Card.Correspondent.Name"></div>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Исполнитель-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Исполнитель")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    <v-workplace-chip v-if="document.Card.ExecuterName" :id="document.Card.ExecuterWorkplaceId" :name="document.Card.ExecuterName" />
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Телефон исполнителя-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Телефон_исполнителя")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ document.Card.Phone ? document.Card.Phone : $t("Не_указано") }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Подписал-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Подписал")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ document.Card.SignerName ? document.Card.SignerName : $t("Не_указано") }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" class="section-wrapper">

                                <!--Секция Краткое содержание-->
                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Краткое_содержание")}}</div>

                                        <!--Поле Документ-основание-->
                                        <v-row class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="2">
                                                <label class="f-label">{{$t("Документ-основание")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="10">
                                                <div class="onlyReadData">
                                                    <v-document-link
                                                        :isViewMode="isViewMode"
                                                        :id="document.ParentId"
                                                        :type="'Documents.ProtocolDocument'"
                                                        :text="dataSource.Data.Object.MainDocumentName"
                                                    />
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Краткое содержание-->
                                        <v-row class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="2">
                                                <label class="f-label">{{$t("Краткое_содержание")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="10">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Theme }}
                                                </div>
                                                <v-textarea v-else
                                                    :value="document.Theme"
                                                    @input="update({ property: 'Data.Object.Document.Theme', value: $event })"
                                                    rows="3"
                                                    no-resize
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                >
                                                </v-textarea>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                                <!--Секция Контролирующие-->
                                <v-card flat v-if="typeIsIAS">
                                    <v-card-text>
                                        <div class="form-box-title">{{ $t("Контролирующие") }}</div>

                                        <v-row class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="2">
                                                <label class="f-label">{{ $t("Контролирующие") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="10">
                                                <div class="icon-click-row-group">
                                                    <div v-if="!isViewMode" @click="onControlersSelect">
                                                        <v-icon small v-tooltip.left-center="$t('Выбрать_контролирующих')">
                                                            fas fa-edit
                                                        </v-icon>
                                                    </div>
                                                    <!--
                                                    <div class="chipAreaNoData d-flex align-center pl-1" 
                                                        v-if="!isViewMode && controlers.length == 0">
                                                        <v-select-members-chip
                                                            target-type="Member"
                                                            @on-select="onSelectControlerFromChip"
                                                            :items-params="controlersSelectParams"
                                                        />
                                                    </div>
                                                    -->
                                                    <div class="onlyReadData more-per-lab-wrap">
                                                        <draggable class="list-group" v-model="controlers" v-bind="dragOptions">
                                                            <transition-group type="transition" :name="'flip-list'">
                                                                <v-workplace-chip
                                                                    v-for="controler in controlers"
                                                                    :disable-info="true"
                                                                    :id="controler.Id"
                                                                    :key="controler.Id"
                                                                    :name="controler.Name"
                                                                    :canDelete="!isViewMode"
                                                                    :delFunc="onRemoveSelectedControler"
                                                                />
                                                            </transition-group>
                                                        </draggable>

                                                        <v-select-members-chip
                                                            v-if="!isViewMode"
                                                            target-type="Member"
                                                            @on-select="onSelectControlerFromChip"
                                                            :items-params="controlersSelectParams"
                                                        />
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                            </v-col>

                        </v-row>
                    </v-form>
                </template>

                <template v-else-if="!!item && item.FormId == 'Control'">
                    <ControlTab
                        :document="document"
                        :ref="item.FormId"
                    />
                </template>

                <template v-else-if="!!item">
                    <component
                        :is="$mapComponent(item.FormId)" 
                        :ref="item.FormId" 
                        :id="document.id"
                    />
                </template>

            </v-tab-item>

        </v-tabs-items>
        <SendForExecuteDlg v-if="isSendForExecuteDlgVisible" />
    </div>
</template>

<script>
import i18n from '@/i18n'
import sys from '@/services/system';
import { mapActions, mapGetters } from 'vuex';
import Toolbar from '@/components/documents/Toolbar.vue';
import DocumentPanel from '@/components/documents/DocumentPanel.vue';
import DocumentStatusCard from '@/components/documents/DocumentStatus';
import ControlTab from '@/components/documents/tabs/static/Control';
import _ from 'lodash';
import draggable from 'vuedraggable'

export default {
    name: "ProtocolActItemCard",
    components: {
        Toolbar,
        DocumentPanel,
        DocumentStatusCard,
        ControlTab,
        //RouteParamsTab,
        SendForExecuteDlg: () => import('@/components/orders/dialogs/SendForExecutionDlg'),
        draggable
    },
    asyncComputed: {
        protocolTypeItems: {
            async get () {
                let items = Array.from(await this.$store.dispatch('documents/references/getReference', { id: 1035 }));

                if (this.protocolTypeSearch)
                    return items.filter(i => sys.checkSearch(i.Value, this.protocolTypeSearch) || i.id == this.document?.Card?.ProtocolType?.id);
                else
                    return items;
            },
            default: [],
            watch: ['protocolTypeSearch']
        },
    },
    computed: {
        ...mapGetters('orders/dialogs/sendForExecution', {isSendForExecuteDlgVisible: 'isVisible'}),
        ...mapGetters('documents/references', ['GetReference']),
        ...mapGetters('documents/references', { periodControlTypes: 'GetPeriodControlTypes', workWeekDays: 'GetWorkWeekDays'}),
        ...mapGetters('global/auth', { userEnterpriseId: 'getUserEnterpriseId' }),
        ...mapGetters('global/actionsource', { controlInfo: 'getControlInfo', dataSource: 'getDataSource', dataSourceType: 'getDataSourceType' }),
        ...mapGetters({ 
            requiredNotZeroRule: 'getNumberNotZeroRule',
            requiredRule: 'getRequiredRule',
            multipleRule: 'getMultipleRule',
            integerRule: 'getIntegerRule',
            integerNotNull: 'getIntegerNotNullRule',
            everydayPeriodRule: 'getEverydayPeriodRule',
            monthlyPeriodRule: 'getMonthlyPeriodRule',
            kvartalPeriodRule: 'getKvartalPeriodRule'
        }),
        isViewMode() {
            return this.dataSource.Data.FormId == "0201141";
        },
        document() {
            return this.dataSource?.Data?.Object?.Document;
        },
        isRegistered() {
            return this.document.RegState == 1 || this.document.RegState == 4;
        },
        controlers: {
            get: function(){
                let result = [];
                if (this.document.ProcessInfo && this.document.ProcessInfo.Adjustments[0].AdjustmentIds.length > 0){
                    
                    for (let i=0; i<this.document.ProcessInfo.Adjustments[0].AdjustmentIds.length; i++){
                        result.push({Id:this.document.ProcessInfo.Adjustments[0].AdjustmentIds[i], Name:this.document.ProcessInfo.Adjustments[0].AdjustmentNames[i]});
                    }
                }
                return result;
            },
            set: function(newValue){
                if (this.document.ProcessInfo == null) {
                    this.updateSource(
                    {
                        property: 'Data.Object.Document.ProcessInfo',
                        value:
                        {
                            __type: 'DocumentProcessInfoExtended:#Avrora.Objects.Modules.Docflow.CommonObjects',
                            is_new_record: true,
                            Adjustments: [],
                            SignerWorkplaceId: '00000000-0000-0000-0000-000000000000',
                            SignerName: ''
                        }
                    });
                }
                this.updateSource({ property: 'Data.Object.Document.ProcessInfo.Adjustments', value: [{ Parallel: false, AdjustmentIds: newValue.map(x => x.Id), AdjustmentNames: newValue.map(x => x.Name) }] });
            }
        },
        typeIsIAS: {
            get: function() {
                return this.document.Card.ClientType == 1;
            },
            set: function(newValue) {
                this.updateSource({ property: 'Data.Object.Document.Card.ClientType', value: newValue ? 1 : 0});
            }
        },
        controlType:{
            get: function() {
                return this.document.Card.Periodicity != 0 ? 101 : this.document.Card.ControlType;
            },
            set: function(newValue) {
                if (newValue == 101){
                    this.updateSource({ property: 'Data.Object.Document.Card.ControlType', value: 1 });
                    this.calcControlDate();
                }
                else {
                    this.updateSource({ property: 'Data.Object.Document.Card.ControlType', value: newValue });
                    this.periodDeltaType = 0;
                    this.periodDelta = null;
                    this.periodEndDate = null;
                }
            }
        },
        controlDate: {
            get: function() {
                return this.document.Card.ControlDate ? this.$moment(this.document.Card.ControlDate).format('YYYY-MM-DD') : null;
            },
            set: function(newValue) {
                this.updateSource({ property: 'Data.Object.Document.Card.ControlDate', value: newValue ? `/Date(${Date.parse(newValue)})/` : null });
            }
        },
        periodDeltaType:{
            get: function() {
                return this.document.Card.Periodicity;
            },
            set: function(newValue) {
                this.updateSource({ property: 'Data.Object.Document.Card.Periodicity', value: newValue });
            }
        },
        periodDelta:{
            get: function() {
                return this.document.Card.PeriodIncrement;
            },
            set: function(newValue) {
                this.updateSource({ property: 'Data.Object.Document.Card.PeriodIncrement', value: newValue });
            }
        },
        periodEndDate: {
            get: function() {
                return this.document.Card.PeriodEndDate ? this.$moment(this.document.Card.PeriodEndDate).format('YYYY-MM-DD') : null;
            },
            set: function(newValue) {
                this.updateSource({ property: 'Data.Object.Document.Card.PeriodEndDate', value: newValue ? `/Date(${Date.parse(newValue)})/` : null })
            }
        },
        isConsiderWeekends: {
            get: function() {
                return this.document.Card.IsConsiderWeekends;
            },
            set: function(newValue) {
                this.updateSource({ property: 'Data.Object.Document.Card.IsConsiderWeekends', value: newValue });
            }
        },
        minDate() {
            return null; //this.$moment(this.document?.CreateDate ?? new Date(-8640000000000000)).format('YYYY-MM-DD');
        },
        maxData() {
            return this.$moment(this.document?.OuterLimit ?? new Date(8640000000000000)).format('YYYY-MM-DD');
        },
        pages() {
            let pages = 
            [
                { Count: -1, FormId: this.document?.id, Name: i18n.t("Карточка") }
            ];
            
            if (!this.document?.is_new_record)
                pages.push({ Count: -1, FormId: "Control", Name: i18n.t("Контроль") });

            pages = pages.concat(this.dataSource?.Data?.Object?.Pages ?? []);

            pages = pages.filter( x => x.FormId != "0202010" )

            return pages;
        },
        concatedRegistrationData() {
            let result = i18n.t("Не_указано");

            if (this.document.Card.RegNumber?.length)
                result = `№ ${this.document.Card.RegNumber}`;

            if (this.document.Card.RegDate)
                result += ` ${this.$t("от_время")} ${this.formatDate(this.document.Card.RegDate)} г.`;

            return result;
        },
        documentLanguages() {
            return this.document.Card.Languages?.data_list ?? [];
        },
        controlersSelectParams() {
            return {
                title: i18n.t("Выбор_контролирующих"),
                multiple: true,
                includeInner: true,
                includeOuter: true,
                selected: [ ...this.controlers.map(x => x.Id) ]
            };
        },
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: this.isViewMode,
                ghostClass: "ghost"
            };
        },
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    data () {
        return {
            tab: null,
            //для выбора даты исходящего в режиме редактирования
            outDateMenu: false,
            //для выбора корреспондента
            correspondentName: null,
            dragControlerInProcess: false,
            controlTypes: [
                { id: 1, Value: this.$t("Контроль") },
                { id: 2, Value: this.$t("Особый_контроль") },
                { id: 101, Value: this.$t("Периодический_контроль") },
            ],
            controlDateMenu: false,
            endDateMenu: false,
            periodControlType: 1,
            workWeekDay: 1,
            everydayDateMenu: false,
            everydayDate: '',
            everydayDelta: 1,
            monthlyDateMenu: false,
            monthlyDate: '',
            monthlyDelta: 1,
            anuallyDateMenu: false,
            anuallyDate: '',
            kvartalDelta: 1,
            todayDate: this.$moment().format('YYYY-MM-DD'),
            randomDateMenu: false,
            randomDate: '',
            protocolTypeSearch: "",
        }
    },
    methods: {
        ...mapActions('global/actionsource', { updateSource: 'updateDataSource' }),
        //обработка клика по вкладкам документа
        onTabClicked (formId) {
            if (formId === this.document?.id) return;

            let component = this.$refs[formId]?.[0];

            if (component)
                if (typeof component.update === 'function')
                    component.update();
        },
        //обработка клика по тулбару и маппинг в действия хранилища
        async onToolbarClick (event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('global/actionsource/')).map(i => {return i.split('/')[2]})
            
            if (actions.includes(button.Action)) {
                if(button.Action == 'Save' || button.Action == 'SaveAndClose'){
                    let validate_result = this.$refs.form[0].validate();
                    if(validate_result){
                        await this.$store.dispatch(`global/actionsource/${button.Action}`, {event, button});        
                    }
                    else{
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                    }
                }
                else
                {
                    await this.$store.dispatch(`global/actionsource/${button.Action}`, {event, button});
                }
            }
            else
                this.$notify.alert(`Действие_${button.Action}_не_реализовано.`);
        },
        calcControlDate(){
            let date = new Date();
            this.periodEndDate = '';
            switch(this.periodControlType){
                case 1:
                    this.controlDate = this.everydayDate;
                    this.periodDeltaType = 1;
                    this.periodDelta = this.everydayDelta;
                    break;
                case 2:
                    date.setDate(date.getDate() + ((this.workWeekDay - date.getDay() + 7) % 7 ));
                    this.controlDate =  date;
                    this.periodDeltaType = 1;
                    this.periodDelta = 7;
                    break;
                case 3:
                    this.controlDate = this.monthlyDate;
                    this.periodDeltaType = 2;
                    this.periodDelta = this.monthlyDelta;
                    break;
                case 4:{
                        let controlDateYear = date.getFullYear();
                        let controlDateMonth = Math.floor((date.getMonth() + 1) / 3) * 3;
                        date = new Date(controlDateYear, controlDateMonth, this.kvartalDelta, 0, 0, 0, 0);
                        if (date < new Date()){
                            controlDateMonth = controlDateMonth+3
                            if (controlDateMonth > 11){
                                controlDateMonth = controlDateMonth - 11;
                                controlDateYear++;  
                            }
                            date = new Date(controlDateYear, controlDateMonth, this.kvartalDelta, 0, 0, 0, 0);
                        }
                        this.controlDate =  date;
                        this.periodDeltaType = 2;
                        this.periodDelta = 3;
                    }
                    break;
                case 5:
                    this.controlDate = this.anuallyDate;
                    this.periodDeltaType = 3;
                    this.periodDelta = 1;
                    break;
                case 6:
                    this.controlDate = this.randomDate;
                    this.periodDeltaType = 4;
                    this.periodDelta = 0;
                    break;
            }
        },
        allowedDates(val) {
            return this.isConsiderWeekends ? ![0, 6].includes(new Date(val).getDay()) : true;
        },
        formatDate (source) {
            return this.$moment(source).format('DD.MM.YYYY');
        },        
        onDragControlerStart() {
            this.dragControlerInProcess = true;
        },
        onDragControlerEnd() {
            this.dragControlerInProcess = false;
        },
        update: _.debounce(function (object) {
            this.updateSource(object);
        }, 250),
        async onControlersSelect () {
            try {
                let recipients = await this.$store.dispatch('global/dialogs/selectMembersNew/open', this.controlersSelectParams);
                this.controlers = recipients.map(x => ({ Id: x.workplaceId, Name: x.name }));
            }
            catch (ex) {
                console.log(ex);
            }
        },
        onSelectControlerFromChip(e) {
            let list = this.controlers;
            list.push({ Id: e.value.workplaceId, Name: e.value.name });
            this.controlers = list;
        },
        onRemoveSelectedControler(id) {
            let tmp = Array.from(this.controlers);
            let itemToRemove = tmp.find(x => x.Id === id);

            if (itemToRemove) {
                let index = tmp.indexOf(itemToRemove);
                
                if (index > -1) {
                    tmp.splice(index, 1);
                    this.controlers = tmp;
                }
            }
        },    
    },
    async created() {        
        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.[0]?.validate();
        })();
    },
    updated() {
        if (this.dataSource !== null) {
            this.$refs?.form?.[0]?.validate();
        }
    },
    mounted() {        
        this.$eventBus.$on('set-active-tab', async ({ FormId }) => {
            let page = this.pages.find(x => x.FormId === FormId);

            if (page) {
                let targetIndex = this.pages.indexOf(page);

                if (this.tab != targetIndex)
                    this.tab = targetIndex;
                else
                    this.$eventBus.$emit('update-active-tab');
            }
        });
        
    },
    beforeDestroy() {
        this.$eventBus.$off('set-active-tab');
    }
}
</script>